import {Suspense, lazy} from 'react'
import { Navigate, useLocation } from "react-router-dom";
// import Routers from "./Routers";
// import SocketIOContextProvider from "./components/Contexts/SocketIOContext";
import Toaster from "./components/Helpers/Toaster";
import Default from "./components/Partials/Default";

import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import LoadingSpinner from './components/Spinners/LoadingSpinner';

if (process.env.REACT_APP_NODE_ENV === 'production') {
    disableReactDevTools();
}
const Routers = lazy(()=>import('./Routers'))
function App() {
  const { pathname } = useLocation();
  return (
    <Default>
      {/* <SocketIOContextProvider> */}
        <>
          {pathname.startsWith("/@") ? (
            <Navigate to="/app" replace={true} />
          ) : (
            <Suspense fallback={<LoadingSpinner size="32" color="sky-blue" height="h-screen" />}>
              <Routers />
            </Suspense>
          )}
          <Toaster />
        </>
      {/* </SocketIOContextProvider> */}
    </Default>
  );
}

export default App;
