import React, { useEffect, useState } from "react";
import {useLocation} from 'react-router-dom'
import DarkModeContext from "../Contexts/DarkModeContext";
import axios from 'axios'

function Default({ children }) {
  const location = useLocation()

  const [nocache, setNoCache] = useState(false) // holds cache/nocache value

  // dark mode setup
  const [theme, setTheme] = useState(null);

  // country mode setup
  const [countryMode, setCountryMode] = useState(localStorage.getItem('cnt') ? localStorage.getItem('cnt')?.toUpperCase() : '')


  const queryParams = new URLSearchParams(location?.search);
  const country = queryParams.get("cnt")?.toUpperCase();

  const handleThemeSwitch = () => {
    setTheme(theme === "dark" ? "light" : "dark");
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (data) => {
          const pos = data.coords;
          const position = {
            lat: pos.latitude,
            long: pos.longitude,
          };
          axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${position.lat}&lon=${position.long}&zoom=18&addressdetails=1`).then(res =>{
            localStorage.setItem("myloc", res?.data?.address?.country);
            // console.log('LOC', res?.data?.address?.country)
            // localStorage.setItem("myloc", JSON.stringify(position));
          }).catch(err => {
            console.log('ERR', err)
          })
        },
        (err) => {
          localStorage.setItem("myloc", JSON.stringify('not supported'));
        }
      );
    } else {
      localStorage.setItem("myloc", JSON.stringify('not supported'));
    }
  }

  useEffect(() => {
    if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  }, []);

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);

  useEffect(()=>{
    if(country){
      setCountryMode(country)
      localStorage.setItem('cnt', country)
    }
  },[countryMode])

  useEffect(()=>{
    getLocation()
  },[])

  return (
    <>
      <DarkModeContext.Provider value={{ theme, handleThemeSwitch, countryMode, nocache, setNoCache }}>
        {children && children}
      </DarkModeContext.Provider>
    </>
  );
}

export default Default;
